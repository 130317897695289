<template>
  <div class="box-page-new">
    <NavbarOne
      class="d-md-none d-lg-none"
      :showNavbar="showNavbar"
      :showCart="showCart"
      :showCartDesktop="showCartDesktop"
      :showSearch="showSearch"
      :showLogo="showLogo"
    >
    </NavbarOne>
    <v-row class="content-table mt-1 mb-5">
      <v-col cols="12" md="12">
        <div class="d-flex justify-content align-center mb-2 pb-3">
          <localized-link to="/company/employee/master">
            <v-icon class="ml-4">mdi-arrow-left</v-icon>
          </localized-link>
          <h4 class="ml-2">{{ $t("page.employee.add_new_employee") }}</h4>
        </div>
        <v-form v-model="valid" lazy-validation ref="form">
          <div class="card--cart-order">
            <v-row v-for="(itemField, i) in basicField" :key="`itemInput-${i}`">
              <v-col cols="12" md="4" class="field-padding">
                <v-select
                  outlined
                  dense
                  class="mt-0 uppercase-input fontSet"
                  v-model="itemField.identityType"
                  :items="identityType"
                  :item-text="
                    $i18n.locale == 'en' ? `name` : `name_${$i18n.locale}`
                  "
                  item-value="id"
                  :rules="[
                    (v) =>
                      !!v ||
                      `${$t('field.identity_type')} ${$t('is_required')}`,
                  ]"
                  :label="$t('field.identity_type')"
                  required
                  @change="selectID(itemField.identityType, i, basicField)"
                ></v-select>
              </v-col>
              <v-col cols="12" md="5" class="field-padding">
                <v-text-field
                  class="mt-0 uppercase-input fontSet"
                  v-model="itemField.identity_number"
                  :rules="[
                      v =>
                        !!v ||
                        `${$t('field.identity_number')} ${$t('is_required')}`,
                      v =>
                        (itemField.identityType === 128
                          ? /^\d+$/.test(v)
                          : true) || $t('field.invalid_id_number'),
                      v =>
                        (itemField.identityType === 128
                          ? /^\d{16}$/.test(v)
                          : true) || $t('field.id_number_must_16'),
                    ]"
                  :label="$t('field.identity_number')"
                  outlined
                  required
                  dense
                  @change="checkID(basicField)"
                ></v-text-field>
                <!-- @change="checkID(itemField.identity_number, i, basicField)" -->
              </v-col>
              <v-col cols="12" md="2" class="field-padding">
                <v-checkbox
                  v-model="itemField.radioSet"
                  :label="$t('field.primary_id')"
                  class="mt-0 uppercase-input fontSet"
                  @change="checkBoxSet(itemField.radioSet, basicField)"
                ></v-checkbox>
              </v-col>
              <v-col
                cols="12"
                md="1"
                class="d-flex align-start justify-center pt-2 pl-0"
              >
                <!-- Add Svg Icon-->
                <!-- @click="addField(itemField, basicField)" -->
                <svg
                  @click="addField(basicField)"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  class="cursor-pointer"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    fill="#00a4b3"
                    d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                  />
                </svg>
                <!--Remove Svg Icon-->
                <svg
                  v-show="basicField.length > 1"
                  @click="removeField(i, basicField)"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  class="ml-2 cursor-pointer"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    fill="#ff5252 "
                    d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                  />
                </svg>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="4" class="field-padding">
                <v-autocomplete
                  outlined
                  dense
                  class="mt-0 uppercase-input fontSet"
                  v-model="payload.citizenship"
                  :search-input.sync="searchInputCitizenship"
                  :loading="fieldLoading.citizen_ship"
                  hide-no-data
                  :items="country_citizen"
                  @change="selectedCitizen(payload.citizenship)"
                  item-text="name"
                  item-value="id"
                  :rules="[
                    (v) =>
                      !!v || `${$t('field.citizenship')} ${$t('is_required')}`,
                  ]"
                  :label="$t('field.citizenship')"
                  required
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="4" class="field-padding">
                <v-text-field
                  class="mt-0 uppercase-input fontSet"
                  v-model="payload.employee_number"
                  :rules="[
                    (v) =>
                      !!v ||
                      `${$t('field.employee_number')} ${$t('is_required')}`,
                  ]"
                  :label="$t('field.employee_number')"
                  outlined
                  required
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4" class="field-padding">
                <v-text-field
                  class="mt-0 uppercase-input fontSet"
                  v-model="payload.department"
                  :rules="[
                    (v) =>
                      !!v || `${$t('field.department')} ${$t('is_required')}`,
                  ]"
                  :label="$t('field.department')"
                  outlined
                  required
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" class="field-padding">
                <v-autocomplete
                  class="mt-0 uppercase-input fontSet"
                  v-model="payload.jobPost"
                  :items="jobPosition"
                  item-text="name"
                  item-value="id"
                  :rules="[
                    (v) => !!v || `${$t('field.jobpost')} ${$t('is_required')}`,
                  ]"
                  :label="$t('field.jobpost')"
                  outlined
                  required
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" class="field-padding">
                <v-autocomplete
                  class="mt-0 uppercase-input fontSet"
                  v-model="payload.levelEmployee"
                  :items="levels"
                  item-text="name"
                  item-value="id"
                  :rules="[
                    (v) => !!v || `${$t('field.level')} ${$t('is_required')}`,
                  ]"
                  :label="$t('field.level')"
                  outlined
                  required
                  dense
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" class="field-padding">
                <v-text-field
                  class="mt-0 uppercase-input fontSet"
                  v-model="payload.first_name"
                  :rules="[
                    (v) =>
                      !!v || `${$t('field.first_name')} ${$t('is_required')}`,
                  ]"
                  :label="$t('field.first_name')"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="field-padding">
                <v-text-field
                  class="mt-0 uppercase-input fontSet"
                  v-model="payload.last_name"
                  :rules="[
                    (v) =>
                      !!v || `${$t('field.last_name')} ${$t('is_required')}`,
                  ]"
                  :label="$t('field.last_name')"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6" class="field-padding">
                <v-text-field
                  class="mt-0 uppercase-input fontSet"
                  v-model="payload.email"
                  :rules="[rules.required, rules.email]"
                  label="Email"
                  outlined
                  required
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="field-padding">
                <v-row>
                  <v-col cols="4" class="field-padding-top">
                    <PhoneNumber
                      :items="phoneCodeItems"
                      :value="defaultPhoneCode"
                      class="input-phone uppercase-input fontSet"
                      :rules="[
                        (v) =>
                          !!v ||
                          `${$t('field.phone_number')} ${$t('is_required')}`,
                      ]"
                      @changePhoneCode="(selected) => updDate(selected)"
                    />
                  </v-col>
                  <v-col cols="8" class="field-padding-top">
                    <v-text-field
                      outlined
                      required
                      dense
                      v-model="formatedPhoneNumber"
                      :rules="[
                        (v) =>
                          !!v ||
                          `${$t('field.phone_number')} ${$t('is_required')}`,
                      ]"
                      :label="$t('field.phone_number')"
                      class="mt-0 uppercase-input fontSet"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" class="field-padding">
                <v-autocomplete
                  outlined
                  dense
                  class="mt-0 uppercase-input fontSet"
                  v-model="payload.birthCountry"
                  :search-input.sync="searchInputCountry"
                  :loading="fieldLoading.country_of_birth"
                  hide-no-data
                  :items="country"
                  item-text="name"
                  item-value="id"
                  :rules="[
                    (v) =>
                      !!v ||
                      `${$t('field.country_of_birth')} ${$t('is_required')}`,
                  ]"
                  :label="$t('field.country_of_birth')"
                  required
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" class="field-padding">
                <v-text-field
                  outlined
                  dense
                  class="mt-0 uppercase-input fontSet"
                  v-model="payload.birthPlace"
                  :rules="[
                    (v) =>
                      !!v ||
                      `${$t('field.place_of_birth')} ${$t('is_required')}`,
                  ]"
                  :label="$t('field.place_of_birth')"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3" class="field-padding">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                 
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="mt-0 uppercase-input fontSet"
                      v-model="dateFormatted"
                      :label="$t('field.birth_date')"
                      :rules="[
                        (v) =>
                          !!v ||
                          `${$t('field.birth_date')} ${$t('is_required')}`,
                      ]"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      hint="DD-MM-YYYY format"
                      outlined
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :locale="$i18n.locale"
                    v-model="payload.date_of_birth"
                    no-title
                    @change="updDate()"
                    @input="menu = false"
                    :max="
                      new Date(
                        Date.now() - new Date().getTimezoneOffset() * 60000
                      )
                        .toISOString()
                        .substr(0, 10)
                    "
                  >
                    <v-spacer></v-spacer>
                   
                  </v-date-picker>
                  <!-- </v-dialog> -->
                </v-menu>
              </v-col>
              <v-col cols="12" md="3" class="field-padding">
                <v-autocomplete
                  class="mt-0 uppercase-input fontSet"
                  v-model="payload.gender"
                  :items="selectGender"
                  :item-text="
                    $i18n.locale == 'en' ? `name` : `name_${$i18n.locale}`
                  "
                  item-value="id"
                  :rules="[
                    (v) => !!v || `${$t('field.gender')} ${$t('is_required')}`,
                  ]"
                  :label="$t('field.gender')"
                  outlined
                  required
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3" class="field-padding">
                <v-autocomplete
                  outlined
                  dense
                  class="mt-0 uppercase-input fontSet"
                  v-model="payload.bloodType"
                  :items="bloodType"
                  item-text="text"
                  item-value="value"
                  :label="$t('field.blood_type')"
                  :rules="[
                    (v) =>
                      !!v || `${$t('field.blood_type')} ${$t('is_required')}`,
                  ]"
                  required
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3" class="field-padding">
                <v-autocomplete
                  outlined
                  dense
                  class="mt-0 uppercase-input fontSet"
                  v-model="payload.selectMartialStatus"
                  :items="martialStatus"
                  :item-text="
                    $i18n.locale == 'en' ? `name` : `name_${$i18n.locale}`
                  "
                  item-value="id"
                  :rules="[
                    (v) =>
                      !!v ||
                      `${$t('field.marital_status')} ${$t('is_required')}`,
                  ]"
                  :label="$t('field.marital_status')"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" class="field-padding">
                <v-autocomplete
                  outlined
                  dense
                  class="mt-0 uppercase-input fontSet"
                  v-model="payload.selectReligion"
                  :items="religion"
                  :item-text="
                    $i18n.locale == 'en' ? 'name' : `name_${$i18n.locale}`
                  "
                  item-value="id"
                  :rules="[
                    (v) =>
                      !!v || `${$t('field.religion')} ${$t('is_required')}`,
                  ]"
                  :label="$t('field.religion')"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" class="field-padding">
                <v-autocomplete
                  outlined
                  dense
                  class="mt-0 uppercase-input fontSet"
                  v-model="payload.selectEthnicity"
                  :items="ethnicity"
                  :item-text="
                    $i18n.locale == 'en' ? `name` : `name_${$i18n.locale}`
                  "
                  item-value="id"
                  :rules="[
                    (v) => !!v || `${$t('field.race')} ${$t('is_required')}`,
                  ]"
                  :label="$t('field.race')"
                ></v-autocomplete>
              </v-col>
            </v-row>
          
            <v-row
              v-for="(insuranceItemField, i) in insuranceSetField"
              :key="`insuranceItemInput-${i}`"
            >
              <v-col cols="12" md="3" class="field-padding">
                <v-select
                  outlined
                  dense
                  class="mt-0 uppercase-input fontSet"
                  v-model="insuranceItemField.company_bp_id"
                  :items="companyBp"
                  item-text="name"
                  item-value="id"
                  :label="$t('page.employee.insurance_company')"
                  @change="
                    selectCompanyID(
                      insuranceItemField.company_bp_id,
                      i,
                      insuranceSetField
                    )
                  "
                ></v-select>
              </v-col>
              <v-col cols="12" md="4" class="field-padding">
                <v-text-field
                  class="mt-0 uppercase-input fontSet"
                  v-model="insuranceItemField.number"
                  :label="$t('field.insurance_number')"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4" class="field-padding">
              
                <v-menu
                  v-model="insuranceItemField.menuCompany"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="mt-0 uppercase-input fontSet"
                      v-model="insuranceItemField.dateFormattedCompany"
                      :label="$t('field.insurance_expired_at')"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      hint="DD-MM-YYYY format"
                      outlined
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :locale="$i18n.locale"
                    v-model="insuranceItemField.expired_at"
                    no-title
                    @change="updateInsurance(insuranceItemField, i)"
                    @input="insuranceItemField.menuCompany = false"
                  >
                    <v-spacer></v-spacer>
                  
                  </v-date-picker>
                  <!-- </v-dialog> -->
                </v-menu>
              </v-col>
              <v-col
                cols="12"
                md="1"
                class="d-flex align-start justify-center pt-2 pl-0"
              >
                <!-- Add Svg Icon-->
                <!-- @click="
                    addFieldInsurance(insuranceItemField, insuranceSetField)
                  " -->
                <svg
                  @click="addFieldInsurance(insuranceSetField)"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  class="cursor-pointer"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    fill="#00a4b3"
                    d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                  />
                </svg>
                <!--Remove Svg Icon-->
                <svg
                  v-show="insuranceSetField.length > 1"
                  @click="removeFieldInsurance(i, insuranceSetField)"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  class="ml-2 cursor-pointer"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    fill="#ff5252 "
                    d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                  />
                </svg>
              </v-col>
            </v-row>
            <v-row v-if="showHideAddressWni == true">
              <v-col cols="12" md="12" class="field-padding d-block">
                <v-text-field
                  class="mt-0 uppercase-input fontSet"
                  v-model="payload.addressInput"
                  :label="$t('field.address')"
                  readonly
                  outlined
                  :rules="[
                    (v) => !!v || `${$t('field.address')} ${$t('is_required')}`,
                  ]"
                  @click.stop="openModalAddress"
                  dense
                  required
                ></v-text-field>
              </v-col>
              <input type="hidden" v-model="payload.subdistrict_code" />
            </v-row>
            <v-row v-if="showHideAddressWni == false">
              <v-col cols="12" md="12" class="field-padding d-none">
                <v-text-field
                  class="mt-0 uppercase-input fontSet"
                  :label="$t('field.address')"
                  readonly
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row v-if="showHideAddressWna == true">
              <v-col cols="12" md="4" class="field-padding d-block">
                <v-autocomplete
                  outlined
                  dense
                  class="mt-0 uppercase-input fontSet"
                  v-model="payload.countryWna"
                  :search-input.sync="searchInputCountryWna"
                  :loading="fieldLoading.country_wna"
                  hide-no-data
                  :items="country_wna_all"
                  item-text="name"
                  item-value="id"
                  :rules="[
                    (v) => !!v || `${$t('field.country')} ${$t('is_required')}`,
                  ]"
                  :label="$t('field.country')"
                  required
                  @change="selectedCountryWna(payload.countryWna)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="4" class="field-padding d-block">
                <v-autocomplete
                  outlined
                  dense
                  class="mt-0 uppercase-input fontSet"
                  v-model="payload.provinceWna"
                  hide-no-data
                  :items="province_wna_all"
                  @change="selectedProvinceWna(payload.provinceWna)"
                  item-text="name"
                  item-value="id"
                  :label="$t('field.province')"
                  :disabled="disabledProvince"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="4" class="field-padding d-block">
                <v-autocomplete
                  outlined
                  dense
                  class="mt-0 uppercase-input fontSet"
                  v-model="payload.cityWna"
                  hide-no-data
                  :items="city_wna_all"
                  @change="selectedCityWna(payload.cityWna)"
                  item-text="name"
                  item-value="id"
                  :label="$t('field.city')"
                  :disabled="disabledCity"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row v-if="showHideAddressWna == false">
              <v-col cols="12" md="4" class="field-padding d-none">
                <v-autocomplete
                  outlined
                  dense
                  class="mt-0 uppercase-input fontSet"
                  hide-no-data
                  :items="country_wna_all"
                  item-text="name"
                  item-value="id"
                  :label="$t('field.country')"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="4" class="field-padding d-none">
                <v-autocomplete
                  outlined
                  dense
                  class="mt-0 uppercase-input fontSet"
                  hide-no-data
                  :items="province_wna_all"
                  item-text="name"
                  item-value="id"
                  :label="$t('field.province')"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="4" class="field-padding d-none">
                <v-autocomplete
                  outlined
                  dense
                  class="mt-0 uppercase-input fontSet"
                  hide-no-data
                  :items="city_wna_all"
                  item-text="name"
                  item-value="id"
                  :label="$t('field.city')"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row v-show="showHideAddressWna == true">
              <v-col cols="12" md="4" class="field-padding">
                <v-autocomplete
                  outlined
                  dense
                  class="mt-0 uppercase-input fontSet"
                  v-model="payload.districtWna"
                  hide-no-data
                  :items="district_wna_all"
                  @change="selectedDistrictWna(payload.districtWna)"
                  item-text="name"
                  item-value="id"
                  :label="$t('field.district')"
                  :disabled="disabledDistrict"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="4" class="field-padding">
                <v-autocomplete
                  outlined
                  dense
                  class="mt-0 uppercase-input fontSet"
                  v-model="payload.subDistrictWna"
                  hide-no-data
                  :items="subDistrict_wna_all"
                  @change="selectedSubDistrictWna(payload.subDistrictWna)"
                  item-text="name"
                  item-value="id"
                  :label="$t('field.sub_district')"
                  :disabled="disabledSubdistrict"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="4" class="field-padding">
                <v-text-field
                  class="mt-0 uppercase-input fontSet"
                  v-model="payload.postalCodeWna"
                  :label="$t('field.postal_code')"
                  outlined
                  dense
                  :disabled="disabledPostal"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row v-show="showHideAddressWniWna == true">
              <v-col cols="12" class="field-padding">
                <v-text-field
                  class="mt-0 uppercase-input fontSet"
                  v-model="payload.address"
                  :rules="[
                    (v) =>
                      !!v ||
                      `${$t('field.address_detail')} ${$t('is_required')}`,
                  ]"
                  :label="$t('field.address_detail')"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
          <div class="d-flex align-center justify-center">
            <v-btn
              :disabled="!valid"
              class="mt-6 button-register fontSet"
              @click="validate"
              color="white"
              v-show="showing == false"
            >
              {{ $t("save") }}
            </v-btn>
          </div>
        </v-form>
      </v-col>
    </v-row>

    <address-pop-up
      v-if="modalAddressIsOpen"
      @modalAddressClose="closeModalAddress"
      @setAddress="setAddress"
    />
  </div>
</template>

<script>
// import TabContent from "@/components/v2/tabs/index.vue";
import API from "@/service/api_service.js";
import Auth from "@/service/auth_service";
import AddressPopUp from "@/components/general/AddressPopUp.vue";
import NavbarOne from "@/components/v2/topbar/navbar_one.vue";
import PhoneNumber from "@/components/v2/input/phonenumber.vue";
import moment from "moment";

export default {
  components: { AddressPopUp, NavbarOne, PhoneNumber },
  data: () => ({
    // dropdown
    fieldLoading: {
      country_of_birth: false,
      citizen_ship: false,
      country_wna: false,
    },
    searchInputCountry: "",
    searchInputCitizenship: "",
    searchInputCountryWna: "",

    // end dropdown
    basicField: [{ identityType: "", identity_number: "", radioSet: false }],
    insuranceSetField: [
      {
        company_bp_id: "",
        number: "",
        expired_at: "",
        dateFormattedCompany: "",
        menuCompany: false,
      },
    ],
    setDataBasic: [],
    showNavbar: true,
    showCart: false,
    showCartDesktop: false,
    showSearch: false,
    showLogo: true,
    valid: true,
    showing: false,
    modal: false,
    modalInsurances: false,
    payload: {
      countryWna: "",
      provinceWna: "",
      cityWna: "",
      districtWna: "",
      subDistrictWna: "",
      postalCodeWna: "",
      citizenship: 102,
      employee_number: "",
      department: "",
      first_name: "",
      last_name: "",
      
      gender: "",
      date_of_birth: "",
      phone_number: "",
      email: "",
      address: "",
      subdistrict_code: "",
    
      addressInput: "",
      birthCountry: 102,
      birthPlace: "",
      bloodType: "",
      selectMartialStatus: "",
      selectEthnicity: 829,
      selectReligion: "",
      levelEmployee: "",
      
      jobPost: "",
    },
    country_wna_all: [],
    province_wna_all: [],
    city_wna_all: [],
    district_wna_all: [],
    subDistrict_wna_all: [],
    levels: [],
    jobPosition: [],
    identityType: [],
    companyBp: [],
    country: [],
    country_citizen: [],
    found: false,
    dialog: false,
    modalAddressIsOpen: false,
    selectCountry: [],
    selectProvince: [],
    selectCity: [],
    selectDistrict: [],
    selectSubdistrict: [],
    selectGender: [],
    martialStatus: [],
    bloodType: [],
    ethnicity: [],
    religion: [],
    checkExistData: [],
    showHideAddressWni: true,
    showHideAddressWna: false,
    showHideAddressWniWna: true,
    disabledProvince: true,
    disabledCity: true,
    disabledDistrict: true,
    disabledSubdistrict: true,
    disabledPostal: true,
    paramNew: "",

    rules: {
      required: (value) => !!value || "This field is required.",
      counter: (value) => value.length <= 13 || "Max 13 characters",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    },
    defaultPhoneCode: "+62",
    phoneCodeItems: [
      {
        flag: "af",
        name: "Afghanistan",
        iso: "(af)",
        code: "+93",
      },
      {
        flag: "al",
        name: "Albania",
        iso: "(al)",
        code: "+355",
      },
      {
        flag: "dz",
        name: "Algeria",
        iso: "(dz)",
        code: "+213",
      },
      {
        flag: "as",
        name: "American Samoa",
        iso: "(as)",
        code: "+684",
      },
      {
        flag: "ad",
        name: "Andorra",
        iso: "(ad)",
        code: "+376",
      },
      {
        flag: "ao",
        name: "Angola",
        iso: "(ao)",
        code: "+244",
      },
      {
        flag: "ai",
        name: "Anguilla",
        iso: "(ai)",
        code: "+1-264",
      },
      {
        flag: "aq",
        name: "Antarctica",
        iso: "(aq)",
        code: "+672",
      },
      {
        flag: "ag",
        name: "Antigua and Barbuda",
        iso: "(ag)",
        code: "+1-268",
      },
      {
        flag: "ar",
        name: "Argentina",
        iso: "(ar)",
        code: "+54",
      },
      {
        flag: "am",
        name: "Armenia",
        iso: "(am)",
        code: "+374",
      },
      {
        flag: "aw",
        name: "Aruba",
        iso: "(as)",
        code: "+297",
      },
      {
        flag: "au",
        name: "Australia",
        iso: "(au)",
        code: "+61",
      },
      {
        flag: "at",
        name: "Austria",
        iso: "(at)",
        code: "+43",
      },
      {
        flag: "az",
        name: "Azerbaijan",
        iso: "(az)",
        code: "+994",
      },
      {
        flag: "bs",
        name: "Bahamas",
        iso: "(bs)",
        code: "+1-242",
      },
      {
        flag: "bh",
        name: "Bahrain",
        iso: "(bh)",
        code: "+973",
      },
      {
        flag: "bd",
        name: "Bangladesh",
        iso: "(bd)",
        code: "+880",
      },
      {
        flag: "by",
        name: "Belarus",
        iso: "(by)",
        code: "+1-246",
      },
      {
        flag: "be",
        name: "Belgium",
        iso: "(be)",
        code: "+93",
      },
      {
        flag: "bz",
        name: "Belize",
        iso: "(bz)",
        code: "+93",
      },
      {
        flag: "bj",
        name: "Benin",
        iso: "(bj)",
        code: "+355",
      },
      {
        flag: "bm",
        name: "Bermuda",
        iso: "(bm)",
        code: "+213",
      },
      {
        flag: "bt",
        name: "Bhutan",
        iso: "(bt)",
        code: "+684",
      },
      {
        flag: "bo",
        name: "Bolivia ",
        iso: "(bo)",
        code: "+376",
      },
      {
        flag: "ba",
        name: "Bosnia and Herzegovina",
        iso: "(ba)",
        code: "+244",
      },
      {
        flag: "bw",
        name: "Botswana",
        iso: "(bw)",
        code: "+1-264",
      },
      {
        flag: "br",
        name: "Brazil",
        iso: "(br)",
        code: "+672",
      },
      {
        flag: "vg",
        name: "British Virgin Islands",
        iso: "(vg)",
        code: "+1-268",
      },
      {
        flag: "bn",
        name: "Brunei Darussalam",
        iso: "(bn)",
        code: "+54",
      },

      {
        flag: "bg",
        name: "Bulgaria",
        iso: "(bg)",
        code: "+374",
      },
      {
        flag: "bg",
        name: "Burkina Faso",
        iso: "(bg)",
        code: "+297",
      },
      {
        flag: "bi",
        name: "Burundi",
        iso: "(bi)",
        code: "+257",
      },
      {
        flag: "kh",
        name: "Cambodia",
        iso: "(kh)",
        code: "+855",
      },
      {
        flag: "cm",
        name: "Cameroon",
        iso: "(cm)",
        code: "+237",
      },

      {
        flag: "ca",
        name: "Canada",
        iso: "(ca)",
        code: "+1",
      },
      {
        flag: "cv",
        name: "Cape Verde",
        iso: "(cv)",
        code: "+238",
      },
      {
        flag: "ky",
        name: "Cayman Islands",
        iso: "(ky)",
        code: "+1-345*",
      },
      {
        flag: "cf",
        name: "Central African Republic",
        iso: "(cf)",
        code: "+236",
      },

      ////////////////////////////////////
      {
        flag: "td",
        name: "Chad",
        iso: "(td)",
        code: "+235",
      },
      {
        flag: "cl",
        name: "Chile",
        iso: "(cl)",
        code: "+56",
      },
      {
        flag: "cn",
        name: "China",
        iso: "(cn)",
        code: "+86",
      },
      {
        flag: "hk",
        name: "Hong Kong",
        iso: "(hk)",
        code: "+852",
      },
      {
        flag: "ad",
        name: "Macao",
        iso: "(ad)",
        code: "+853",
      },
      {
        flag: "cx",
        name: "Christmas Island",
        iso: "(cx)",
        code: "+61",
      },
      {
        flag: "co",
        name: "Colombia",
        iso: "(co)",
        code: "+57",
      },
      {
        flag: "km",
        name: "Comoros",
        iso: "(km)",
        code: "+269",
      },
      {
        flag: "cg",
        name: "Congo",
        iso: "(cg)",
        code: "+242",
      },
      {
        flag: "cd",
        name: "Congo",
        iso: "(cd)",
        code: "+243",
      },
      {
        flag: "ck",
        name: "Cook Islands",
        iso: "(ck)",
        code: "+682",
      },
      {
        flag: "cr",
        name: "Costa Rica",
        iso: "(cr)",
        code: "+506",
      },
      {
        flag: "ci",
        name: "Côte d'Ivoire",
        iso: "(ci)",
        code: "+225",
      },
      {
        flag: "hr",
        name: "Croatia",
        iso: "(hr)",
        code: "+385",
      },
      {
        flag: "cu",
        name: "Cuba",
        iso: "(cu)",
        code: "+53",
      },
      {
        flag: "cy",
        name: "Cyprus",
        iso: "(cy)",
        code: "+357",
      },
      {
        flag: "cz",
        name: "Czech Republic",
        iso: "(cz)",
        code: "+420",
      },
      {
        flag: "dk",
        name: "Denmark",
        iso: "(dk)",
        code: "+45",
      },
      {
        flag: "dm",
        name: "Dominica",
        iso: "(dm)",
        code: "+1-767*",
      },
      {
        flag: "do",
        name: "Dominican Republic",
        iso: "(do)",
        code: "+1-809*",
      },
      {
        flag: "ec",
        name: "Ecuador",
        iso: "(ec)",
        code: "+593",
      },
      {
        flag: "eg",
        name: "Egypt",
        iso: "(eg)",
        code: "+20",
      },
      {
        flag: "sv",
        name: "El Salvador",
        iso: "(sv)",
        code: "+503",
      },
      {
        flag: "gq",
        name: "Equatorial Guinea",
        iso: "(gq)",
        code: "+240",
      },
      {
        flag: "er",
        name: "Eritrea ",
        iso: "(er)",
        code: "+291",
      },
      {
        flag: "ee",
        name: "Estonia",
        iso: "(ee)",
        code: "+372",
      },
      {
        flag: "et",
        name: "Ethiopia",
        iso: "(et)",
        code: "+251",
      },
      {
        flag: "fk",
        name: "Falkland Islands",
        iso: "(fk)",
        code: "+500",
      },
      {
        flag: "fo",
        name: "Faroe Islands",
        iso: "(fo)",
        code: "+298",
      },
      {
        flag: "fj",
        name: "Fiji Islands",
        iso: "(fj)",
        code: "+679",
      },

      {
        flag: "fi",
        name: "Finland",
        iso: "(fi)",
        code: "+358",
      },
      {
        flag: "fr",
        name: "France",
        iso: "(fr)",
        code: "+33",
      },
      {
        flag: "gf",
        name: "French Guiana",
        iso: "(gf)",
        code: "+594",
      },
      {
        flag: "pf",
        name: "French Polynesia",
        iso: "(pf)",
        code: "+689",
      },
      {
        flag: "ga",
        name: "Gabonese Republic",
        iso: "(ga)",
        code: "+241",
      },

      {
        flag: "gm",
        name: "Gambia",
        iso: "(gm)",
        code: "+220",
      },
      {
        flag: "ge",
        name: "Georgia",
        iso: "(ge)",
        code: "+995",
      },
      {
        flag: "de",
        name: "Germany",
        iso: "(de)",
        code: "+49",
      },
      {
        flag: "gh",
        name: "Ghana",
        iso: "(gh)",
        code: "+233",
      },
      //////////////////////
      {
        flag: "gi",
        name: "Gibraltar",
        iso: "(gi)",
        code: "+350",
      },
      {
        flag: "gr",
        name: "Greece",
        iso: "(gr)",
        code: "+30",
      },
      {
        flag: "gl",
        name: "Greenland",
        iso: "(gl)",
        code: "+299",
      },
      {
        flag: "gd",
        name: "Grenada",
        iso: "(gd)",
        code: "+1-473",
      },
      {
        flag: "gp",
        name: "Guadeloupe",
        iso: "(gp)",
        code: "+590",
      },
      {
        flag: "gu",
        name: "Guam",
        iso: "(gu)",
        code: "+1-671*",
      },
      {
        flag: "gt",
        name: "Guatemala",
        iso: "(gt)",
        code: "+502",
      },
      {
        flag: "gn",
        name: "Guinea",
        iso: "(gn)",
        code: "+224",
      },
      {
        flag: "gw",
        name: "Guinea-Bissau",
        iso: "(gw)",
        code: "+245",
      },
      {
        flag: "gy",
        name: "Guyana",
        iso: "(gy)",
        code: "+592",
      },
      {
        flag: "ht",
        name: "Haiti",
        iso: "(ht)",
        code: "+509",
      },
      {
        flag: "hn",
        name: "Honduras",
        iso: "(hn)",
        code: "+504",
      },
      {
        flag: "hu",
        name: "Hungary",
        iso: "(hu)",
        code: "+36",
      },
      {
        flag: "is",
        name: "Iceland",
        iso: "(is)",
        code: "+354",
      },
      {
        flag: "in",
        name: "India",
        iso: "(in)",
        code: "+91",
      },
      {
        flag: "id",
        name: "Indonesia",
        iso: "(id)",
        code: "+62",
      },
      {
        flag: "ir",
        name: "Iran",
        iso: "(ir)",
        code: "+98",
      },
      {
        flag: "iq",
        name: "Iraq",
        iso: "(iq)",
        code: "+964",
      },
      {
        flag: "ie",
        name: "Ireland",
        iso: "(ie)",
        code: "+353",
      },
      {
        flag: "il",
        name: "Israel",
        iso: "(il)",
        code: "+972",
      },
      {
        flag: "it",
        name: "Italy",
        iso: "(it)",
        code: "+39",
      },
      {
        flag: "jm",
        name: "Jamaica",
        iso: "(jm)",
        code: "+1-876*",
      },
      {
        flag: "jp",
        name: "Japan",
        iso: "(jp)",
        code: "+81",
      },
      {
        flag: "jo",
        name: "Jordan",
        iso: "(jo)",
        code: "+962",
      },
      {
        flag: "kz",
        name: "Kazakhstan ",
        iso: "(kz)",
        code: "+7",
      },
      {
        flag: "ke",
        name: "Kenya",
        iso: "(ke)",
        code: "+254",
      },
      {
        flag: "ki",
        name: "Kiribati",
        iso: "(ki)",
        code: "+686",
      },
      {
        flag: "kp",
        name: "North Korea",
        iso: "(kp)",
        code: "+850",
      },
      {
        flag: "kr",
        name: "South Korea",
        iso: "(kr)",
        code: "+82",
      },
      {
        flag: "kw",
        name: "Kuwait",
        iso: "(kw)",
        code: "+965",
      },
      {
        flag: "kg",
        name: "Kyrgyzstan",
        iso: "(kg)",
        code: "+996",
      },

      {
        flag: "la",
        name: "Laos",
        iso: "(la)",
        code: "+856",
      },
      {
        flag: "lv",
        name: "Latvia",
        iso: "(lv)",
        code: "+371",
      },
      {
        flag: "lb",
        name: "Lebanon",
        iso: "(lb)",
        code: "+961",
      },
      {
        flag: "ls",
        name: "Lesotho",
        iso: "(ls)",
        code: "+266",
      },
      {
        flag: "lr",
        name: "Liberia",
        iso: "(lr)",
        code: "+231",
      },

      {
        flag: "ly",
        name: "Libya",
        iso: "(ly)",
        code: "+218",
      },
      {
        flag: "li",
        name: "Liechtenstein",
        iso: "(li)",
        code: "+423",
      },
      {
        flag: "lt",
        name: "Lithuania",
        iso: "(lt)",
        code: "+370",
      },
      {
        flag: "lu",
        name: "Luxembourg",
        iso: "(lu)",
        code: "+352",
      },
      ////////////
      {
        flag: "mk",
        name: "North Macedonia",
        iso: "(mk)",
        code: "+389",
      },
      {
        flag: "mg",
        name: "Madagascar",
        iso: "(mg)",
        code: "+261",
      },
      {
        flag: "mw",
        name: "Malawi",
        iso: "(mw)",
        code: "+265",
      },
      {
        flag: "my",
        name: "Malaysia",
        iso: "(my)",
        code: "+60",
      },
      {
        flag: "mv",
        name: "Maldives",
        iso: "(mv)",
        code: "+960",
      },
      {
        flag: "ml",
        name: "Mali Republic",
        iso: "(ml)",
        code: "+223",
      },
      {
        flag: "mt",
        name: "Malta",
        iso: "(mt)",
        code: "+356",
      },
      {
        flag: "mh",
        name: "Marshall Islands",
        iso: "(mh)",
        code: "+692",
      },
      {
        flag: "mq",
        name: "Martinique",
        iso: "(mq)",
        code: "+596",
      },
      {
        flag: "mr",
        name: "Mauritania",
        iso: "(mr)",
        code: "+222",
      },
      {
        flag: "mu",
        name: "Mauritius",
        iso: "(mu)",
        code: "+230",
      },
      {
        flag: "yt",
        name: "Mayotte",
        iso: "(yt)",
        code: "+269",
      },
      {
        flag: "mx",
        name: "Mexico",
        iso: "(mx)",
        code: "+52",
      },
      {
        flag: "fm",
        name: "Micronesia",
        iso: "(fm)",
        code: "+691",
      },
      {
        flag: "md",
        name: "Moldova",
        iso: "(md)",
        code: "+373",
      },
      {
        flag: "mc",
        name: "Monaco",
        iso: "(mc)",
        code: "+377",
      },
      {
        flag: "mn",
        name: "Mongolia",
        iso: "(mn)",
        code: "+976",
      },
      {
        flag: "me",
        name: "Montserrat",
        iso: "(me)",
        code: "+1-664*",
      },
      {
        flag: "ma",
        name: "Morocco",
        iso: "(ma)",
        code: "+212",
      },
      {
        flag: "mz",
        name: "Mozambique",
        iso: "(mz)",
        code: "+258",
      },
      {
        flag: "mm",
        name: "Myanmar",
        iso: "(mm)",
        code: "+95",
      },
      {
        flag: "na",
        name: "Namibia",
        iso: "(na)",
        code: "+264",
      },
      {
        flag: "nr",
        name: "Nauru",
        iso: "(nr)",
        code: "+674",
      },
      {
        flag: "np",
        name: "Nepal",
        iso: "(np)",
        code: "+977",
      },
      {
        flag: "nl",
        name: "Netherlands",
        iso: "(nl)",
        code: "+31",
      },
      {
        flag: "nc",
        name: "New Caledonia",
        iso: "(nc)",
        code: "+687",
      },
      {
        flag: "nz",
        name: "New Zealand",
        iso: "(nz)",
        code: "+64",
      },
      {
        flag: "ni",
        name: "Nicaragua",
        iso: "(ni)",
        code: "+505",
      },
      {
        flag: "ne",
        name: "Niger",
        iso: "(ne)",
        code: "+227",
      },
      {
        flag: "ng",
        name: "Nigeria",
        iso: "(ng)",
        code: "+234",
      },
      {
        flag: "nu",
        name: "Niue",
        iso: "(nu)",
        code: "+683",
      },

      {
        flag: "nf",
        name: "Norfolk Island",
        iso: "(nf)",
        code: "+672",
      },
      {
        flag: "mp",
        name: "Northern Mariana Islands",
        iso: "(mp)",
        code: "+1-670*",
      },
      {
        flag: "no",
        name: "Norway",
        iso: "(no)",
        code: "+47",
      },
      {
        flag: "om",
        name: "Oman",
        iso: "(om)",
        code: "+968",
      },
      {
        flag: "pk",
        name: "Pakistan",
        iso: "(pk)",
        code: "+92",
      },

      {
        flag: "pw",
        name: "Palau",
        iso: "(pw)",
        code: "+680",
      },
      {
        flag: "pa",
        name: "Panama",
        iso: "(pa)",
        code: "+507",
      },
      {
        flag: "pg",
        name: "Papua New Guinea",
        iso: "(pg)",
        code: "+675",
      },
      {
        flag: "py",
        name: "Paraguay",
        iso: "(py)",
        code: "+595",
      },
      ////////////
      {
        flag: "pe",
        name: "Peru",
        iso: "(pe)",
        code: "+51",
      },
      {
        flag: "ph",
        name: "Philippines",
        iso: "(ph)",
        code: "+63",
      },
      {
        flag: "pl",
        name: "Poland",
        iso: "(pl)",
        code: "+48",
      },
      {
        flag: "pt",
        name: "Portugal",
        iso: "(pt)",
        code: "+351",
      },
      {
        flag: "pr",
        name: "Puerto Rico",
        iso: "(pr)",
        code: "+1-787",
      },
      {
        flag: "qa",
        name: "Qatar",
        iso: "(qa)",
        code: "+974",
      },
      {
        flag: "re",
        name: "Réunion",
        iso: "(re)",
        code: "+262",
      },
      {
        flag: "ro",
        name: "Romania",
        iso: "(ro)",
        code: "+40",
      },
      {
        flag: "ru",
        name: "Russian Federation",
        iso: "(ru)",
        code: "+7",
      },
      {
        flag: "rw",
        name: "Rwanda",
        iso: "(rw)",
        code: "+250",
      },
      {
        flag: "sh",
        name: "St. Helena",
        iso: "(sh)",
        code: "+290",
      },
      {
        flag: "kn",
        name: "St. Kitts/Nevis",
        iso: "(kn)",
        code: "+1-869*",
      },
      {
        flag: "lc",
        name: "St Lucia",
        iso: "(lc)",
        code: "+1-758*",
      },
      {
        flag: "pm",
        name: "St. Pierre & Miquelon",
        iso: "(pm)",
        code: "+508",
      },
      {
        flag: "vc",
        name: "St. Vincent & Grenadines",
        iso: "(vc)",
        code: "+1-784*",
      },
      {
        flag: "sm",
        name: "San Marino",
        iso: "(sm)",
        code: "+378",
      },
      {
        flag: "st",
        name: "Sao Tomo and Principe",
        iso: "(st)",
        code: "+239",
      },
      {
        flag: "sa",
        name: "Saudi Arabia",
        iso: "(sa)",
        code: "+966",
      },
      {
        flag: "sn",
        name: "Senegal",
        iso: "(sn)",
        code: "+221",
      },
      {
        flag: "rs",
        name: "Serbia & Montenegro",
        iso: "(rs)",
        code: "+381",
      },
      {
        flag: "sc",
        name: "Seychelles Republic",
        iso: "(sc)",
        code: "+248",
      },
      {
        flag: "sl",
        name: "Sierra Leone",
        iso: "(sl)",
        code: "+232",
      },
      {
        flag: "sg",
        name: "Singapore",
        iso: "(sg)",
        code: "+65",
      },
      {
        flag: "sk",
        name: "Slovak Republic",
        iso: "(sk)",
        code: "+421",
      },
      {
        flag: "si",
        name: "Slovenia",
        iso: "(si)",
        code: "+386",
      },
      {
        flag: "sb",
        name: "Solomon Islands",
        iso: "(sb)",
        code: "+677",
      },
      {
        flag: "so",
        name: "Somalia",
        iso: "(so)",
        code: "+252",
      },
      {
        flag: "za",
        name: "South Africa",
        iso: "(za)",
        code: "+27",
      },
      {
        flag: "es",
        name: "Spain",
        iso: "(es)",
        code: "+34",
      },
      {
        flag: "lk",
        name: "Sri Lanka",
        iso: "(lk)",
        code: "+94",
      },
      {
        flag: "sd",
        name: "Sudan",
        iso: "(sd)",
        code: "+249",
      },
      {
        flag: "sr",
        name: "Suriname",
        iso: "(sr)",
        code: "+597",
      },
      {
        flag: "se",
        name: "Sweden",
        iso: "(se)",
        code: "+46",
      },
      {
        flag: "ch",
        name: "Switzerland",
        iso: "(ch)",
        code: "+41",
      },
      {
        flag: "sy",
        name: "Syrian Arab Republic",
        iso: "(sy)",
        code: "+963",
      },
      {
        flag: "tw",
        name: "Taiwan",
        iso: "(tw)",
        code: "+886",
      },

      {
        flag: "tj",
        name: "Tajikistan",
        iso: "(tj)",
        code: "+992",
      },
      {
        flag: "tz",
        name: "Tanzania",
        iso: "(tz)",
        code: "+255",
      },
      {
        flag: "th",
        name: "Thailand",
        iso: "(th)",
        code: "+66",
      },
      {
        flag: "tg",
        name: "Togolese Republic",
        iso: "(tg)",
        code: "+228",
      },
      ////////////
      {
        flag: "tk",
        name: "Tokelau",
        iso: "(tk)",
        code: "+690",
      },
      {
        flag: "to",
        name: "Tonga",
        iso: "(to)",
        code: "+676",
      },
      {
        flag: "tt",
        name: "Trinidad and Tobago",
        iso: "(tt)",
        code: "+1-868",
      },
      {
        flag: "tn",
        name: "Tunisia",
        iso: "(tn)",
        code: "+216",
      },
      {
        flag: "tr",
        name: "Turkey",
        iso: "(tr)",
        code: "+90",
      },
      {
        flag: "tm",
        name: "Turkmenistan",
        iso: "(tm)",
        code: "+993",
      },
      {
        flag: "tc",
        name: "Turks and Caicos Islands",
        iso: "(tc)",
        code: "+1~649",
      },
      {
        flag: "tv",
        name: "Tuvalu",
        iso: "(tv)",
        code: "+688",
      },
      {
        flag: "ug",
        name: "Uganda",
        iso: "(ug)",
        code: "+256",
      },
      {
        flag: "ua",
        name: "Ukraine",
        iso: "(ua)",
        code: "+380",
      },
      {
        flag: "ae",
        name: "United Arab Emirates",
        iso: "(ae)",
        code: "+971",
      },
      {
        flag: "gb",
        name: "United Kingdom",
        iso: "(gb)",
        code: "+44",
      },
      {
        flag: "us",
        name: "United States of America",
        iso: "(us)",
        code: "+1",
      },
      {
        flag: "uy",
        name: "Uruguay",
        iso: "(uy)",
        code: "+598",
      },
      {
        flag: "uz",
        name: "Uzbekistan",
        iso: "(uz)",
        code: "+998",
      },
      {
        flag: "vu",
        name: "Vanuatu",
        iso: "(vu)",
        code: "+678",
      },
      {
        flag: "ve",
        name: "Venezuela",
        iso: "(ve)",
        code: "+58",
      },
      {
        flag: "vn",
        name: "Vietnam",
        iso: "(vn)",
        code: "+84",
      },
      {
        flag: "vi",
        name: "Virgin Islands",
        iso: "(vi)",
        code: "+1-340*",
      },
      {
        flag: "ye",
        name: "Yemen",
        iso: "(ye)",
        code: "+998",
      },
      {
        flag: "zm",
        name: "Zambia",
        iso: "(zm)",
        code: "+260",
      },
      {
        flag: "zw",
        name: "Zimbabwe",
        iso: "(zw)",
        code: "+00",
      },
    ],
    menu: false,
    menuIns: false,
  }),
  watch: {
    searchInputCountry: async function (text, before) {
      this.getCountrySearch(text == null && before != "" ? before : text);
    },
    searchInputCitizenship: async function (text, before) {
      this.getCitizenSearch(text == null && before != "" ? before : text);
    },
    searchInputCountryWna: async function (text, before) {
      this.getWNASearch(text == null && before != "" ? before : text);
    },
  },
  computed: {
    dateFormatted() {
      return this.formatDate(this.payload.date_of_birth);
    },
    // dateFormattedInsurances() {
    //   return this.formatDate(this.payload.insurance_expired_at);
    // },
    // dateFormattedCompany() {

    //   return this.formatDate(this.insuranceSetField[0].expired_at);
    // },
    formatedPhoneNumber: {
      get() {
        return this.payload.phone_number;
      },
      set(param) {
        if (param.length > 4 && param.substring(0, 1) == "0") {
          this.payload.phone_number = param.substring(1);
        } else {
          this.payload.phone_number = param;
        }
      },
    },
  },
  async mounted() {
    this.$store.commit("setLoading", true);
    // identity Type
    API.get(
      `${process.env.VUE_APP_API_TRUST}identity-type/get?show_default=false`,
      {}
    ).then((res) => {
      if (res.statusCode == 200) {
        this.identityType = res.results.sort((a, b) => this.sortByName(a, b));
      }
    });

    // insurance company
    API.get(
      `${process.env.VUE_APP_API_TRUST}business-partner/get?type=INS,PAY`,
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Auth.getToken()}`,
      }
    ).then((res) => {
      if (res.statusCode == 200) {
        this.companyBp = res.results.sort((a, b) => this.sortByName(a, b));
      }
    });

    // Job Position
    API.get(`${process.env.VUE_APP_API_TRUST}employee/get-job-positions`, {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.getToken()}`,
    }).then((res) => {
      if (res.statusCode == 200) {
        this.jobPosition = res.results.sort((a, b) => this.sortByName(a, b));
      }
    });

    // gender
    API.get(`${process.env.VUE_APP_API_TRUST}gender/get`, {}).then((res) => {
      if (res.statusCode == 200) {
        this.selectGender = res.results.sort((a, b) => this.sortByName(a, b));
      }
    });

    // place
    API.get(`${process.env.VUE_APP_API_TRUST}city/get`, {}).then((res) => {
      if (res.statusCode == 200) {
        this.birthPlace = res.results.sort((a, b) => this.sortByName(a, b));
      }
    });
    //blood
    this.bloodType = [
      { value: "-", text: this.$t("unknown") },
      { value: "A+", text: "A+" },
      { value: "A-", text: "A-" },
      { value: "AB+", text: "AB+" },
      { value: "AB-", text: "AB-" },
      { value: "B+", text: "B+" },
      { value: "B-", text: "B-" },
      { value: "O+", text: "O+" },
      { value: "O-", text: "O-" },
    ];
    // martial status
    API.get(`${process.env.VUE_APP_API_TRUST}marital-status/get`, {}).then(
      (res) => {
        if (res.statusCode == 200) {
          this.martialStatus = res.results.sort((a, b) =>
            this.sortByName(a, b)
          );
        }
      }
    );
    // levels
    API.get(`${process.env.VUE_APP_API_TRUST}employee/get-levels`, {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.getToken()}`,
    }).then((res) => {
      if (res.statusCode == 200) {
        this.levels = res.results;
      }
    });
    // religion
    API.get(
      `${process.env.VUE_APP_API_TRUST}religion/get?sort_by_country=ID`,
      {}
    ).then((res) => {
      if (res.statusCode == 200) {
        this.religion = res.results;
      }
    });
    // ethnic
    API.get(`${process.env.VUE_APP_API_TRUST}ethnicity/get`, {}).then((res) => {
      if (res.statusCode == 200) {
        this.ethnicity = res.results.sort((a, b) => this.sortByName(a, b));
      }
    });

    this.$store.commit("setLoading", false);
  },
  methods: {
    async getCountrySearch(text) {
    
      this.fieldLoading.country_of_birth = true;
      // country
      const country = await API.get(
        `${process.env.VUE_APP_API_TRUST}country/get?query=${text}`,
        {}
      );
      if (country.statusCode == 200) {
        this.country = country.results.sort((a, b) => this.sortByName(a, b));
      }
      this.fieldLoading.country_of_birth = false;
    },

    async getCitizenSearch(text) {
     
      this.fieldLoading.citizen_ship = true;
      // country
      const country = await API.get(
        `${process.env.VUE_APP_API_TRUST}country/get?query=${text}`,
        {}
      );
      if (country.statusCode == 200) {
        this.country_citizen = country.results.sort((a, b) =>
          this.sortByName(a, b)
        );
      }
      this.fieldLoading.citizen_ship = false;
    },
    async getWNASearch(text) {
     
      this.fieldLoading.country_wna = true;

      // country not indonesia
      const country = await API.get(
        `${process.env.VUE_APP_API_TRUST}country/get?query=${
          text == null || text == undefined ? "" : text
        }`,
        {}
      );
      if (country.statusCode == 200) {
        const filterNotIndo = country.results.filter((dataNotIndo) => {
          return dataNotIndo.id != 102;
        });
        this.country_wna_all = filterNotIndo.sort((a, b) =>
          this.sortByName(a, b)
        );
      }
      this.fieldLoading.country_wna = false;
    },

    selectedCountryWna(param) {
  
      if (param != null || param != "") {
        this.disabledProvince = false;
        this.disabledCity = true;
        this.disabledDistrict = true;
        this.disabledSubdistrict = true;
        this.disabledPostal = false;

        // province
        API.get(
          `${process.env.VUE_APP_API_TRUST}province/get?country_id=${param}`,
          {}
        ).then((res) => {
          if (res.statusCode == 200) {
            this.province_wna_all = res.results.sort((a, b) =>
              this.sortByName(a, b)
            );
           
          }
        });
      } else {
        this.disabledProvince = true;
        this.disabledCity = true;
        this.disabledDistrict = true;
        this.disabledSubdistrict = true;
        this.disabledPostal = false;
      }
    },
    selectedCitizen(param) {
      if (param == 102) {
        this.showHideAddressWni = true;
        this.showHideAddressWniWna = true;
        this.showHideAddressWna = false;

        API.get(
          `${process.env.VUE_APP_API_TRUST}identity-type/get?show_default=false`,
          {}
        ).then((res) => {
          if (res.statusCode == 200) {
            this.identityType = res.results.sort((a, b) =>
              this.sortByName(a, b)
            );
          }
        });
        
        if(param != this.listEmployee.citizenship_id){
          this.payload.addressInput = "";
        }
        else{
          this.payload.addressInput =
        this.listEmployee.subdistrict +
        ", " +
        this.listEmployee.district +
        ", " +
        this.listEmployee.city +
        ", " +
        this.listEmployee.province +
        ", " +
        this.listEmployee.country_name;
        }
       
      } else {
        this.showHideAddressWni = false;
        this.showHideAddressWniWna = true;
        this.showHideAddressWna = true;

        API.get(
          `${process.env.VUE_APP_API_TRUST}identity-type/get?show_default=false`,
          {}
        ).then((res) => {
          if (res.statusCode == 200) {
            const filterIdentityIndo = this.identityType.filter(
              (dataIdentityIndo) => {
                return dataIdentityIndo.id != 128;
              }
            );
            this.identityType = filterIdentityIndo.sort((a, b) =>
              this.sortByName(a, b)
            );
          }
        });
        if(param != this.listEmployee.citizenship_id){
          this.payload.countryWna = "";
          this.payload.provinceWna = "";
          this.payload.cityWna = "";
          this.payload.districtWna = "";
          this.payload.subDistrictWna = "";
          this.payload.postalCodeWna = "";
        }
        else{
          this.payload.countryWna = this.listEmployee.country_id;
          this.payload.provinceWna = this.listEmployee.province_id;
          this.payload.cityWna = this.listEmployee.city_id;
          this.payload.districtWna = this.listEmployee.district_id;
          this.payload.subDistrictWna = this.listEmployee.subdistrict_id;
          this.payload.postalCodeWna = this.listEmployee.postal_code;
        }
        
      }
    },
    selectedProvinceWna(param) {
    
      if (param != null || param != "") {
        const filterIdSNI = this.province_wna_all.filter((dataNewSni) => {
          return dataNewSni.id == param;
        });

        if (filterIdSNI[0].id_sni == null || filterIdSNI[0].id_sni == "") {
          this.paramNew = "-";
        } else {
          this.paramNew = filterIdSNI[0].id_sni;
        }

        // city
        API.get(
          `${process.env.VUE_APP_API_TRUST}city/get?province_id_sni=${this.paramNew}`,
          {}
        ).then((res) => {
          if (res.statusCode == 200) {
            this.city_wna_all = res.results.sort((a, b) =>
              this.sortByName(a, b)
            );
          
            if (this.city_wna_all.length > 0) {
              this.disabledProvince = false;
              this.disabledCity = false;
              this.disabledDistrict = true;
              this.disabledSubdistrict = true;
              this.disabledPostal = false;
            } else {
              this.disabledProvince = false;
              this.disabledCity = true;
              this.disabledDistrict = true;
              this.disabledSubdistrict = true;
              this.disabledPostal = false;
            }
          }
        });
      } else {
        this.disabledProvince = true;
        this.disabledCity = true;
        this.disabledDistrict = true;
        this.disabledSubdistrict = true;
        this.disabledPostal = false;
      }
    },
    selectedCityWna(param) {
    
      if (param != null || param != "") {
        this.disabledProvince = false;
        this.disabledCity = false;
        this.disabledDistrict = false;
        this.disabledSubdistrict = true;
        this.disabledPostal = false;

        // district
        API.get(
          `${process.env.VUE_APP_API_TRUST}district/get?city_id=${param}`,
          {}
        ).then((res) => {
          if (res.statusCode == 200) {
            this.district_wna_all = res.results.sort((a, b) =>
              this.sortByName(a, b)
            );
          }
        });
      } else {
        this.disabledProvince = true;
        this.disabledCity = true;
        this.disabledDistrict = true;
        this.disabledSubdistrict = true;
        this.disabledPostal = false;
      }
    },

    selectedDistrictWna(param) {
    
      if (param != null || param != "") {
        this.disabledProvince = false;
        this.disabledCity = false;
        this.disabledDistrict = false;
        this.disabledSubdistrict = false;
        this.disabledPostal = false;

        // subdistrict
        API.get(
          `${process.env.VUE_APP_API_TRUST}subdistrict/get?district_id=${param}`,
          {}
        ).then((res) => {
          if (res.statusCode == 200) {
            this.subDistrict_wna_all = res.results.sort((a, b) =>
              this.sortByName(a, b)
            );
          }
        });
      } else {
        this.disabledProvince = true;
        this.disabledCity = true;
        this.disabledDistrict = true;
        this.disabledSubdistrict = true;
        this.disabledPostal = false;
      }
    },
    selectedSubDistrictWna(param) {
      if (param != null || param != "") {
        this.disabledProvince = false;
        this.disabledCity = false;
        this.disabledDistrict = false;
        this.disabledSubdistrict = false;
        this.disabledPostal = false;
      } else {
        this.disabledProvince = true;
        this.disabledCity = true;
        this.disabledDistrict = true;
        this.disabledSubdistrict = true;
        this.disabledPostal = false;
      }
    },

    selectID(valuess, index, fieldType) {
      const filterNewGes = this.basicField.filter((dataNewGes) => {
        return dataNewGes.identityType == valuess;
      });
    
      if (filterNewGes.length > 1) {
        this.$swal({
          text: this.$t("field.identity_warning"),
          showCancelButton: false,
          confirmButtonColor: "#1A428A",
          confirmButtonText: this.$t("ok"),
        });
        fieldType.splice(index, 1);
      } else {
        if (
          fieldType[0].identityType == "" ||
          fieldType[0].identityType == null
        ) {
          this.$swal({
            text: this.$t("field.select_identity_first"),
            showCancelButton: false,
            confirmButtonColor: "#1A428A",
            confirmButtonText: this.$t("ok"),
          });
          fieldType[0].identity_number = "";
        } else if (
          fieldType[0].identity_number == "" ||
          fieldType[0].identity_number == null
        ) {
          fieldType[0].identity_number = "";
        } else {
          API.get(
            `${process.env.VUE_APP_API_TRUST}employee/get-identity?type=${fieldType[0].identityType}&id_number=${fieldType[0].identity_number}`,
            {
              Authorization: `Bearer ${Auth.getToken()}`,
            }
          ).then((res) => {
            if (res.statusCode == 200) {
              this.checkExistData = res.results;
              if (res.results != null) {
                if (res.results[0].imported == true) {
                  this.$swal({
                    text: this.$t("field.data_employee_exist"),
                    showCancelButton: false,
                    confirmButtonColor: "#1A428A",
                    confirmButtonText: this.$t("ok"),
                  });
                  this.basicField = [
                    { identityType: "", identity_number: "", radioSet: false },
                  ];
                } else {
                  this.$swal({
                    text: this.$t("field.data_patient_exist"),
                    showCancelButton: true,
                    confirmButtonColor: "#1A428A",
                    cancelButtonColor: "#3085d6",
                    confirmButtonText: this.$t("yes"),
                    cancelButtonText: this.$t("no"),
                  }).then((result) => {
                    if (result.isConfirmed) {
                      this.listEmployee = res.results[0];

                      const getDate = new Date(this.listEmployee.date_of_birth);
                      const formatDateBirth = `${getDate.getFullYear()}-${(
                        "0" +
                        (getDate.getMonth() + 1)
                      ).slice(-2)}-${("0" + getDate.getDate()).slice(-2)}`;

                      const phoneFormat = this.listEmployee.phone_number;

                      this.phoneCodeItems.map((item) => {
                        if (
                          item.code ==
                          phoneFormat.substring(0, item.code.length)
                        ) {
                          this.payload.phone_number = phoneFormat.substring(
                            item.code.length
                          );
                          this.defaultPhoneCode = item.code;
                        }
                        return item;
                      });

                      this.birthday = formatDateBirth;

                      if (this.listEmployee.insurance != null) {
                        this.listEmployee.insurance.forEach((element) => {
                          const getDateInsuranceSet = new Date(
                            element.expire_date
                          );
                          const formatDateInsuranceSet = `${getDateInsuranceSet.getFullYear()}-${(
                            "0" +
                            (getDateInsuranceSet.getMonth() + 1)
                          ).slice(-2)}-${(
                            "0" + getDateInsuranceSet.getDate()
                          ).slice(-2)}`;
                          this.insuranceSetField = [
                            ...this.insuranceSetField,
                            {
                              company_bp_id: element.company_bp_id,
                              number: element.insurance_number,
                              expired_at: formatDateInsuranceSet,
                              dateFormattedCompany: this.formatDate(
                                formatDateInsuranceSet
                              ),
                            },
                          ];
                        });
                      } else {
                        this.insuranceSetField = [
                          {
                            company_bp_id: "",
                            number: "",
                            expired_at: "",
                            id: 0,
                            dateFormattedCompany: "",
                            menuCompany: false,
                          },
                        ];
                      }

                      if (this.listEmployee.citizenship_id == 102) {
                        this.showHideAddressWni = true;
                        this.showHideAddressWna = false;
                        this.showHideAddressWniWna = true;
                      } else {
                        this.showHideAddressWni = false;
                        this.showHideAddressWna = true;
                        this.showHideAddressWniWna = true;
                      }

                      if (
                        this.payload.citizenship !=
                        this.listEmployee.citizenship_id
                      ) {
                        this.payload.citizenship =
                          this.listEmployee.citizenship_id;
                        this.getCitizenSearch(this.listEmployee.citizenship);
                        this.payload.countryWna = this.listEmployee.country_id;
                        this.getWNASearch(this.listEmployee.country_name);

                        if (
                          this.listEmployee.province_id != null ||
                          this.listEmployee.province_id != ""
                        ) {
                          this.disabledProvince = false;
                          // province
                          API.get(
                            `${process.env.VUE_APP_API_TRUST}province/get?country_id=${this.listEmployee.country_id}`,
                            {}
                          ).then((res) => {
                            if (res.statusCode == 200) {
                              this.province_wna_all = res.results.sort((a, b) =>
                                this.sortByName(a, b)
                              );
                             
                              const filterIdSNI = this.province_wna_all.filter(
                              (dataNewSni) => {
                                return (
                                  dataNewSni.id == this.listEmployee.province_id
                                );
                              }
                            );
                            var dataIdSNI = filterIdSNI[0].id_sni;
                          
                            if (
                              this.listEmployee.city_id != null ||
                              this.listEmployee.city_id != ""
                            ) {
                              this.disabledCity = false;
                            
                              // city
                              API.get(
                                `${process.env.VUE_APP_API_TRUST}city/get?province_id_sni=${dataIdSNI}`,
                                {}
                              ).then((res) => {
                                if (res.statusCode == 200) {
                                  this.city_wna_all = res.results.sort((a, b) =>
                                    this.sortByName(a, b)
                                  );
                                 
                                  if (
                                    this.listEmployee.district_id != null ||
                                    this.listEmployee.district_id != ""
                                  ) {
                                    this.disabledDistrict = false;
                                    // district
                                    API.get(
                                      `${process.env.VUE_APP_API_TRUST}district/get?city_id=${this.listEmployee.city_id}`,
                                      {}
                                    ).then((res) => {
                                      if (res.statusCode == 200) {
                                        this.district_wna_all =
                                          res.results.sort((a, b) =>
                                            this.sortByName(a, b)
                                          );

                                          if (
                                              this.listEmployee.subdistrict_id != null ||
                                              this.listEmployee.subdistrict_id != ""
                                            ) {
                                              this.disabledSubdistrict = false;
                                              // district
                                              API.get(
                                                `${process.env.VUE_APP_API_TRUST}subdistrict/get?district_id=${this.listEmployee.district_id}`,
                                                {}
                                              ).then((res) => {
                                                if (res.statusCode == 200) {
                                                  this.subDistrict_wna_all =
                                                    res.results.sort((a, b) =>
                                                      this.sortByName(a, b)
                                                    );
                                                    if (
                                                        this.listEmployee.postal_code != null ||
                                                        this.listEmployee.postal_code != ""
                                                      ) {
                                                        this.disabledPostal = false;
                                                      }else{
                                                        this.disabledPostal = false;
                                                      }
                                                }
                                              });
                                            }
                                      }
                                    });
                                  }
                                }
                              });
                            }
                            }
                          });
                          // if()
                        }
                      
                        this.payload.provinceWna =
                          this.listEmployee.province_id;
                        this.payload.cityWna = this.listEmployee.city_id;
                        this.payload.districtWna =
                          this.listEmployee.district_id;
                        this.payload.subDistrictWna =
                          this.listEmployee.subdistrict_id;
                        this.payload.postalCodeWna =
                          this.listEmployee.postal_code;
                      }

                      this.getCountrySearch(this.listEmployee.country_birth);
                      this.payload.birthCountry =
                        this.listEmployee.country_birth_id;

                      this.payload.person_id = this.listEmployee.person_id;
                      this.payload.employee_number =
                        this.listEmployee.employee_number;
                      this.payload.department = this.listEmployee.department;
                      this.payload.first_name = this.listEmployee.first_name;
                      this.payload.last_name = this.listEmployee.last_name;
                      this.payload.gender = this.listEmployee.gender_id;
                      this.payload.date_of_birth = this.birthday;
                      this.payload.levelEmployee =
                        this.listEmployee.employee_level_id;
                      this.payload.jobPost = this.listEmployee.job_position_id;
                      this.payload.email = this.listEmployee.email;
                      this.payload.address = this.listEmployee.address;
                      this.payload.subdistrict_code =
                        this.listEmployee.subdistrict_code;
                      this.payload.addressInput =
                        this.listEmployee.subdistrict +
                        ", " +
                        this.listEmployee.district +
                        ", " +
                        this.listEmployee.city +
                        ", " +
                        this.listEmployee.province +
                        ", " +
                        this.listEmployee.country_name;

                      this.payload.birthPlace =
                        this.listEmployee.place_of_birth;
                      this.payload.bloodType = this.listEmployee.blood_type;
                      this.payload.selectMartialStatus =
                        this.listEmployee.marital_status_id;
                      this.payload.selectEthnicity =
                        this.listEmployee.ethnicity_id;
                      this.payload.selectReligion =
                        this.listEmployee.religion_id;
                    } else {
                      this.basicField = [
                        {
                          identityType: "",
                          identity_number: "",
                          radioSet: false,
                        },
                      ];
                    }
                  });
                }
              } else {
                this.defaultPhoneCode = "+62";
                this.formatedPhoneNumber = "";
                this.payload.citizenship = 102;
                this.getCitizenSearch("INDONESIA");
                this.showHideAddressWna = false;
                this.showHideAddressWni = true;
                this.payload.addressInput = "";
                this.payload.countryWna = "";
                this.payload.provinceWna = "";
                this.payload.cityWna = "";
                this.payload.districtWna = "";
                this.payload.subDistrictWna = "";
                this.payload.postalCodeWna = "";
                this.payload.subdistrict_code = "";
                this.payload.birthCountry = "";
                this.payload.person_id = "";
                this.payload.employee_number = "";
                this.payload.department = "";
                this.payload.first_name = "";
                this.payload.last_name = "";
                this.payload.gender = "";
                this.payload.date_of_birth = "";
                this.payload.levelEmployee = "";
                this.payload.jobPost = "";
                this.payload.email = "";
                this.payload.address = "";

                this.payload.birthPlace = "";
                this.payload.bloodType = "";
                this.payload.selectMartialStatus = "";
                this.payload.selectEthnicity = "";
                this.payload.selectReligion = "";
              }
            }
          });
        }
      }
    },

    checkID(fieldType) {
      
      this.payload.citizenship = "";

      // Checking Is Exist
      if (
        fieldType[0].identityType == "" ||
        fieldType[0].identityType == null
      ) {
        this.$swal({
          text: this.$t("field.select_identity_first"),
          showCancelButton: false,
          confirmButtonColor: "#1A428A",
          confirmButtonText: this.$t("ok"),
        });
        fieldType[0].identity_number = "";
      } else {
        API.get(
          `${process.env.VUE_APP_API_TRUST}employee/get-identity?type=${fieldType[0].identityType}&id_number=${fieldType[0].identity_number}`,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        ).then((res) => {
          if (res.statusCode == 200) {
            this.checkExistData = res.results;
            if (res.results != null) {
              if (res.results[0].imported == true) {
                this.$swal({
                  text: this.$t("field.data_employee_exist"),
                  showCancelButton: false,
                  confirmButtonColor: "#1A428A",
                  confirmButtonText: this.$t("ok"),
                });
                this.basicField = [
                  { identityType: "", identity_number: "", radioSet: false },
                ];
              } else {
                this.$swal({
                  text: this.$t("field.data_patient_exist"),
                  showCancelButton: true,
                  confirmButtonColor: "#1A428A",
                  cancelButtonColor: "#3085d6",
                  confirmButtonText: this.$t("yes"),
                  cancelButtonText: this.$t("no"),
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.listEmployee = res.results[0];

                    const getDate = new Date(this.listEmployee.date_of_birth);
                    const formatDateBirth = `${getDate.getFullYear()}-${(
                      "0" +
                      (getDate.getMonth() + 1)
                    ).slice(-2)}-${("0" + getDate.getDate()).slice(-2)}`;

                    const phoneFormat = this.listEmployee.phone_number;

                    this.phoneCodeItems.map((item) => {
                      if (
                        item.code == phoneFormat.substring(0, item.code.length)
                      ) {
                        this.payload.phone_number = phoneFormat.substring(
                          item.code.length
                        );
                        this.defaultPhoneCode = item.code;
                      }
                      return item;
                    });

                    this.birthday = formatDateBirth;

                    if (this.listEmployee.insurance != null) {
                      this.listEmployee.insurance.forEach((element) => {
                        const getDateInsuranceSet = new Date(
                          element.expire_date
                        );
                        const formatDateInsuranceSet = `${getDateInsuranceSet.getFullYear()}-${(
                          "0" +
                          (getDateInsuranceSet.getMonth() + 1)
                        ).slice(-2)}-${(
                          "0" + getDateInsuranceSet.getDate()
                        ).slice(-2)}`;
                        this.insuranceSetField = [
                          ...this.insuranceSetField,
                          {
                            company_bp_id: element.company_bp_id,
                            number: element.insurance_number,
                            expired_at: formatDateInsuranceSet,
                            dateFormattedCompany: this.formatDate(
                              formatDateInsuranceSet
                            ),
                          },
                        ];
                      });
                    } else {
                      this.insuranceSetField = [
                        {
                          company_bp_id: "",
                          number: "",
                          expired_at: "",
                          id: 0,
                          dateFormattedCompany: "",
                          menuCompany: false,
                        },
                      ];
                    }

                    if (this.listEmployee.citizenship_id == 102) {
                      this.showHideAddressWni = true;
                      this.showHideAddressWna = false;
                      this.showHideAddressWniWna = true;
                    } else {
                      this.showHideAddressWni = false;
                      this.showHideAddressWna = true;
                      this.showHideAddressWniWna = true;
                    }

                    if (
                      this.payload.citizenship !=
                      this.listEmployee.citizenship_id
                    ) {
                      this.payload.citizenship =
                        this.listEmployee.citizenship_id;
                      this.getCitizenSearch(this.listEmployee.citizenship);
                      this.payload.countryWna = this.listEmployee.country_id;
                      this.getWNASearch(this.listEmployee.country_name);

                      if (
                        this.listEmployee.province_id != null ||
                        this.listEmployee.province_id != ""
                      ) {
                        this.disabledProvince = false;
                        // province
                        API.get(
                          `${process.env.VUE_APP_API_TRUST}province/get?country_id=${this.listEmployee.country_id}`,
                          {}
                        ).then((res) => {
                          if (res.statusCode == 200) {
                            this.province_wna_all = res.results.sort((a, b) =>
                              this.sortByName(a, b)
                            );
                           
                            const filterIdSNI = this.province_wna_all.filter(
                              (dataNewSni) => {
                                return (
                                  dataNewSni.id == this.listEmployee.province_id
                                );
                              }
                            );
                            var dataIdSNI = filterIdSNI[0].id_sni;
                           
                            if (
                              this.listEmployee.city_id != null ||
                              this.listEmployee.city_id != ""
                            ) {
                              this.disabledCity = false;
                            
                              // city
                              API.get(
                                `${process.env.VUE_APP_API_TRUST}city/get?province_id_sni=${dataIdSNI}`,
                                {}
                              ).then((res) => {
                                if (res.statusCode == 200) {
                                  this.city_wna_all = res.results.sort((a, b) =>
                                    this.sortByName(a, b)
                                  );
                                 
                                  if (
                                    this.listEmployee.district_id != null ||
                                    this.listEmployee.district_id != ""
                                  ) {
                                    this.disabledDistrict = false;
                                    // district
                                    API.get(
                                      `${process.env.VUE_APP_API_TRUST}district/get?city_id=${this.listEmployee.city_id}`,
                                      {}
                                    ).then((res) => {
                                      if (res.statusCode == 200) {
                                        this.district_wna_all =
                                          res.results.sort((a, b) =>
                                            this.sortByName(a, b)
                                          );

                                          if (
                                              this.listEmployee.subdistrict_id != null ||
                                              this.listEmployee.subdistrict_id != ""
                                            ) {
                                              this.disabledSubdistrict = false;
                                              // district
                                              API.get(
                                                `${process.env.VUE_APP_API_TRUST}subdistrict/get?district_id=${this.listEmployee.district_id}`,
                                                {}
                                              ).then((res) => {
                                                if (res.statusCode == 200) {
                                                  this.subDistrict_wna_all =
                                                    res.results.sort((a, b) =>
                                                      this.sortByName(a, b)
                                                    );
                                                    if (
                                                        this.listEmployee.postal_code != null ||
                                                        this.listEmployee.postal_code != ""
                                                      ) {
                                                        this.disabledPostal = false;
                                                      }
                                                      else{
                                                        this.disabledPostal = false;
                                                      }
                                                }
                                              });
                                            }
                                      }
                                    });
                                  }
                                }
                              });
                            }
                          }
                        });
                      
                      }
                  
                      this.payload.provinceWna = this.listEmployee.province_id;
                      this.payload.cityWna = this.listEmployee.city_id;
                      this.payload.districtWna = this.listEmployee.district_id;
                      this.payload.subDistrictWna =
                        this.listEmployee.subdistrict_id;
                      this.payload.postalCodeWna =
                        this.listEmployee.postal_code;
                    }
                    this.payload.subdistrict_code =
                      this.listEmployee.subdistrict_code;
                    this.payload.addressInput =
                      this.listEmployee.subdistrict +
                      ", " +
                      this.listEmployee.district +
                      ", " +
                      this.listEmployee.city +
                      ", " +
                      this.listEmployee.province +
                      ", " +
                      this.listEmployee.country_name;
                    this.getCountrySearch(this.listEmployee.country_birth);
                    this.payload.birthCountry =
                      this.listEmployee.country_birth_id;

                    this.payload.person_id = this.listEmployee.person_id;
                    this.payload.employee_number =
                      this.listEmployee.employee_number;
                    this.payload.department = this.listEmployee.department;
                    this.payload.first_name = this.listEmployee.first_name;
                    this.payload.last_name = this.listEmployee.last_name;
                    this.payload.gender = this.listEmployee.gender_id;
                    this.payload.date_of_birth = this.birthday;
                    this.payload.levelEmployee =
                      this.listEmployee.employee_level_id;
                    this.payload.jobPost = this.listEmployee.job_position_id;
                    this.payload.email = this.listEmployee.email;
                    this.payload.address = this.listEmployee.address;

                    this.payload.birthPlace = this.listEmployee.place_of_birth;
                    this.payload.bloodType = this.listEmployee.blood_type;
                    this.payload.selectMartialStatus =
                      this.listEmployee.marital_status_id;
                    this.payload.selectEthnicity =
                      this.listEmployee.ethnicity_id;
                    this.payload.selectReligion = this.listEmployee.religion_id;
                  } else {
                    this.basicField = [
                      {
                        identityType: "",
                        identity_number: "",
                        radioSet: false,
                      },
                    ];
                  }
                });
              }
            } else {
              this.defaultPhoneCode = "+62";
              this.formatedPhoneNumber = "";
              this.payload.citizenship = 102;
              this.getCitizenSearch("INDONESIA");
              this.showHideAddressWna = false;
              this.showHideAddressWni = true;
              this.payload.addressInput = "";
              this.payload.countryWna = "";
              this.payload.provinceWna = "";
              this.payload.cityWna = "";
              this.payload.districtWna = "";
              this.payload.subDistrictWna = "";
              this.payload.postalCodeWna = "";
              this.payload.subdistrict_code = "";
              this.payload.birthCountry = "";
              this.payload.person_id = "";
              this.payload.employee_number = "";
              this.payload.department = "";
              this.payload.first_name = "";
              this.payload.last_name = "";
              this.payload.gender = "";
              this.payload.date_of_birth = "";
              this.payload.levelEmployee = "";
              this.payload.jobPost = "";
              this.payload.email = "";
              this.payload.address = "";

              this.payload.birthPlace = "";
              this.payload.bloodType = "";
              this.payload.selectMartialStatus = "";
              this.payload.selectEthnicity = "";
              this.payload.selectReligion = "";
            }
          }
        });
      }
    },
    async selectCompanyID(valuess, index, fieldTypeInsurance) {
      const checkDataSame = this.companyBp.filter((dataSame) => {
        return dataSame.id == valuess;
      });
      const resp = await API.get(`${process.env.VUE_APP_API_TRUST}profile`, {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Auth.getToken()}`,
      });

      if (resp.company.info.company_name == checkDataSame[0].name) {
        fieldTypeInsurance.splice(index, 1);
        this.$swal({
          text: this.$t("field.my_company_warning"),
          showCancelButton: false,
          confirmButtonColor: "#1A428A",
          confirmButtonText: this.$t("ok"),
        });
        this.showing = false;
        fieldTypeInsurance.push({
          company_bp_id: "",
          number: "",
          expired_at: "",
          dateFormattedCompany: "",
        });
      } else {
        this.showing = false;
      }

      const filterNewGes = this.insuranceSetField.filter((dataNewGes) => {
        return dataNewGes.company_bp_id == valuess;
      });

      if (filterNewGes.length > 1) {
        fieldTypeInsurance.splice(index, 1);
        this.$swal({
          text: this.$t("field.insurance_company_warning"),
          showCancelButton: false,
          confirmButtonColor: "#1A428A",
          confirmButtonText: this.$t("ok"),
        });
        fieldTypeInsurance.push({
          company_bp_id: "",
          number: "",
          expired_at: "",
          dateFormattedCompany: "",
        });
      }
    },
    checkBoxSet(value, data) {
      var setDats = [];
      data.forEach((element) => {
        if (element.radioSet == true) {
          setDats = [...setDats, value];
        }
      });

      if (setDats.length > 1) {
        this.showing = true;
        this.$swal({
          text: this.$t("field.primary_warning"),
          showCancelButton: false,
          confirmButtonColor: "#1A428A",
          confirmButtonText: this.$t("ok"),
        });
      } else {
        this.showing = false;
      }
    },
    addField(fieldType) {
      fieldType.push({ identityType: "", identity_number: "", radioSet: "" });
    },
    removeField(index, fieldType) {
      fieldType.splice(index, 1);
      if (fieldType.length == 1) {
        this.showing = false;
      }
    },

    addFieldInsurance(fieldTypeInsurance) {
      fieldTypeInsurance.push({
        company_bp_id: "",
        number: "",
        expired_at: "",
        dateFormattedCompany: "",
      });
    },
    removeFieldInsurance(index, fieldTypeInsurance) {
      fieldTypeInsurance.splice(index, 1);
      if (fieldTypeInsurance.length == 1) {
        this.showing = false;
      }
    },

    validate() {
      if (this.$refs.form.validate() == true) {
        var setInsurance = this.insuranceSetField.filter((dataSet) => {
          return dataSet.number == "";
        });

        if (setInsurance.length > 0) {
          this.insuranceSetField = [];
        }

        if (this.basicField.length > 0) {
          var mappingField = this.basicField.map((test) => {
            if (test.radioSet == "") {
              test.radioSet = false;
            }
            return test;
          });

          mappingField.forEach((element) => {
            this.setDataBasic = [
              ...this.setDataBasic,
              {
                type: element.identityType,
                number: element.identity_number,
                primary: element.radioSet,
              },
            ];
          });
        }

        const checkDataSame = mappingField.filter((dataSame) => {
          return dataSame.radioSet == true;
        });

        if (checkDataSame.length == 0) {
          this.showing = false;
          this.insuranceSetField = [
            {
              company_bp_id: "",
              number: "",
              expired_at: "",
              id: 0,
              dateFormattedCompany: "",
              menuCompany: false,
            },
          ];
          this.$swal({
            text: this.$t("field.primary_null"),
            showCancelButton: false,
            confirmButtonColor: "#1A428A",
            confirmButtonText: this.$t("ok"),
          });
        } else {
          const {
            employee_number,
            department,
            first_name,
            last_name,
          
            gender,
            date_of_birth,
            phone_number,
            email,
            address,
            subdistrict_code,
          
            selectMartialStatus,
            selectReligion,
            bloodType,
            selectEthnicity,
            birthPlace,
            birthCountry,
            levelEmployee,
            jobPost,
            citizenship,
            countryWna,
            provinceWna,
            cityWna,
            districtWna,
            subDistrictWna,
            postalCodeWna,
         
          } = this.payload;
          

          let dataReady = {};
          if (this.checkExistData != null) {
            dataReady = {
              job_position_id: jobPost,
              employee_number: employee_number,
            
              employee_level: levelEmployee,
              department: department,
              first_name: first_name,
              last_name: last_name,
           
              identities: this.setDataBasic,
              insurances: this.insuranceSetField,
              gender: gender,
              date_of_birth: date_of_birth,
              email: email,
              address: address,
              subdistrict_code: subdistrict_code,
             
              phone_number: phone_number.replace(/\s/g, "").replaceAll("-", ""),
              phone_code: this.defaultPhoneCode,
              marital_status_id: selectMartialStatus,
              citizenship: citizenship,
              country_id: countryWna,
              province_id: provinceWna,
              city_id: cityWna,
              district_id: districtWna,
              subdistrict_id: subDistrictWna,
              postal_code: postalCodeWna,
              country_birth_id: birthCountry,
              place_of_birth: birthPlace,
              religion_id: selectReligion,
              ethnicity: selectEthnicity,
              blood_type: bloodType,
              override: true,
              client_unique_id: "tm617a34c4a3b86",
            };
          } else {
            dataReady = {
              job_position_id: jobPost,
              employee_number: employee_number,
           
              employee_level: levelEmployee,
              department: department,
              first_name: first_name,
              last_name: last_name,
             
              identities: this.setDataBasic,
              insurances: this.insuranceSetField,
              gender: gender,
              date_of_birth: date_of_birth,
              email: email,
              address: address,
              subdistrict_code: subdistrict_code,
           
              phone_number: phone_number.replace(/\s/g, "").replaceAll("-", ""),
              phone_code: this.defaultPhoneCode,
              marital_status_id: selectMartialStatus,
              citizenship: citizenship,
              country_id: countryWna,
              province_id: provinceWna,
              city_id: cityWna,
              district_id: districtWna,
              subdistrict_id: subDistrictWna,
              postal_code: postalCodeWna,
              country_birth_id: birthCountry,
              place_of_birth: birthPlace,
              religion_id: selectReligion,
              ethnicity: selectEthnicity,
              blood_type: bloodType,
              override: false,
              client_unique_id: "tm617a34c4a3b86",
            };
          }

          API.post(
            `${process.env.VUE_APP_API_TRUST}employee/add`,
            {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Auth.getToken()}`,
            },
            [dataReady]
          )
            .then((res) => {
              if (res.statusCode === 200) {
                this.$router.push(
                  `/${this.$i18n.locale}/company/employee/master`
                );
                this.toast("success", this.$t("success_insert_data"));
              } else if (res.statusCode == 403) {
              
                Object.keys(res.errors).forEach((element) => {
                  this.toast(
                    "error",
                    typeof res.errors[element] == "object"
                      ? res.errors[element][0]
                      : res.errors[element]
                  );
                });
                this.setDataBasic = [];
              } else if (res.statusCode == 400) {
                Object.keys(res.errors).forEach((element) => {
                  this.toast(
                    "error",
                    typeof res.errors[element] == "object"
                      ? res.errors[element][0]
                      : res.errors[element]
                  );
                });
                this.setDataBasic = [];
              } else if (res.statusCode == 409) {
                this.$swal({
                  text: res.errors["0.override"][0],
                  showCancelButton: true,
                  confirmButtonColor: "#1A428A",
                  cancelButtonColor: "#3085d6",
                  confirmButtonText: this.$t("yes"),
                  cancelButtonText: this.$t("no"),
                }).then((result) => {
                  if (result.isConfirmed) {
                    const {
                      employee_number,
                      department,
                      first_name,
                      last_name,
                    
                      gender,
                      date_of_birth,
                      phone_number,
                      email,
                      address,
                      subdistrict_code,
                     
                      selectMartialStatus,
                      selectReligion,
                      bloodType,
                      selectEthnicity,
                      birthPlace,
                      birthCountry,
                      levelEmployee,
                      jobPost,
                      subDistrictWna,
                      postalCodeWna,
                      citizenship,
                      countryWna,
                      provinceWna,
                      cityWna,
                      districtWna,
                    } = this.payload;
                    const dataReady = {
                      override: true,
                      job_position_id: jobPost,
                      employee_number: employee_number,
                     
                      employee_level: levelEmployee,
                      department: department,
                      first_name: first_name,
                      last_name: last_name,
                    
                      identities: this.setDataBasic,
                      insurances: this.insuranceSetField,
                      gender: gender,
                      date_of_birth: date_of_birth,
                      email: email,
                      address: address,
                      subdistrict_code: subdistrict_code,
                    
                      phone_number: phone_number
                        .replace(/\s/g, "")
                        .replaceAll("-", ""),
                      phone_code: this.defaultPhoneCode,
                      marital_status_id: selectMartialStatus,
                      citizenship: citizenship,
                      country_id: countryWna,
                      province_id: provinceWna,
                      city_id: cityWna,
                      district_id: districtWna,
                      subdistrict_id: subDistrictWna,
                      postal_code: postalCodeWna,
                      country_birth_id: birthCountry,
                      place_of_birth: birthPlace,
                      religion_id: selectReligion,
                      ethnicity: selectEthnicity,
                      blood_type: bloodType,
                      client_unique_id: "tm617a34c4a3b86",
                    };
                    API.post(
                      `${process.env.VUE_APP_API_TRUST}employee/add`,
                      {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${Auth.getToken()}`,
                      },
                      [dataReady]
                    ).then((res) => {
                      if (res.statusCode == 200) {
                        this.$router.push(
                          `/${this.$i18n.locale}/company/employee/master`
                        );
                      } else if (res.statusCode == 403) {
                       
                        Object.keys(res.errors).forEach((element) => {
                          this.toast(
                            "error",
                            typeof res.errors[element] == "object"
                              ? res.errors[element][0]
                              : res.errors[element]
                          );
                        });
                        this.setDataBasic = [];
                      }
                    });
                  } else {
                    const {
                      employee_number,
                      department,
                      first_name,
                      last_name,
                     
                      gender,
                      date_of_birth,
                      phone_number,
                      email,
                      address,
                      subdistrict_code,
                    
                      selectMartialStatus,
                      selectReligion,
                      bloodType,
                      selectEthnicity,
                      birthPlace,
                      birthCountry,
                      levelEmployee,
                      jobPost,
                      subDistrictWna,
                      postalCodeWna,
                      citizenship,
                      countryWna,
                      provinceWna,
                      cityWna,
                      districtWna,
                    } = this.payload;
                    const dataReady = {
                      override: false,
                      job_position_id: jobPost,
                      employee_number: employee_number,
                    
                      employee_level: levelEmployee,
                      department: department,
                      first_name: first_name,
                      last_name: last_name,
                     
                      identities: this.setDataBasic,
                      insurances: this.insuranceSetField,
                      gender: gender,
                      date_of_birth: date_of_birth,
                      email: email,
                      address: address,
                      subdistrict_code: subdistrict_code,
                     
                      phone_number: phone_number
                        .replace(/\s/g, "")
                        .replaceAll("-", ""),
                      phone_code: this.defaultPhoneCode,
                      marital_status_id: selectMartialStatus,
                      citizenship: citizenship,
                      country_id: countryWna,
                      province_id: provinceWna,
                      city_id: cityWna,
                      district_id: districtWna,
                      subdistrict_id: subDistrictWna,
                      postal_code: postalCodeWna,
                      country_birth_id: birthCountry,
                      place_of_birth: birthPlace,
                      religion_id: selectReligion,
                      ethnicity: selectEthnicity,
                      blood_type: bloodType,
                      client_unique_id: "tm617a34c4a3b86",
                    };
                    API.post(
                      `${process.env.VUE_APP_API_TRUST}employee/add`,
                      {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${Auth.getToken()}`,
                      },
                      [dataReady]
                    ).then((res) => {
                      if (res.statusCode == 200) {
                        this.$router.push(
                          `/${this.$i18n.locale}/company/employee/master`
                        );
                      } else if (res.statusCode == 403) {
                      
                        Object.keys(res.errors).forEach((element) => {
                          this.toast(
                            "error",
                            typeof res.errors[element] == "object"
                              ? res.errors[element][0]
                              : res.errors[element]
                          );
                        });
                        this.setDataBasic = [];
                      }
                    });
                  }
                });
              } else {
                this.$swal({
                  icon: "error",
                  text: res.message,
               
                });
                this.setDataBasic = [];
              }
            })
            .catch(() => {
              this.$swal({
                icon: "error",
                text: "Error Add Employee!",
              });
            });
        }
      }
    },

    toast(status, messages) {
      this.$swal
        .mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        })
        .fire({
          icon: status,
          title: messages,
        });
    },
    openModalAddress() {
      this.modalAddressIsOpen = true;
    },
    closeModalAddress() {
      this.modalAddressIsOpen = false;
    },
    setAddress(param) {
      this.payload.addressInput = param.name;
      this.payload.subdistrict_code = param.idsubdistrict;
    },
    sortByName(a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    },
    formatDate(date) {
      if (!date) return null;
      return moment(date)
        .locale(this.$i18n.locale)
        .format("DD-MMM-YYYY")
        .toUpperCase();
    },
    updDate() {
      this.$store.state.date = moment(this.dateFormatted)
        .format("YYYY-MM-DD")
        .toUpperCase();
    },
    updateInsurance(data) {
      this.$store.state.date = moment(data.expired_at)
        .format("YYYY-MM-DD")
        .toUpperCase();

      data.dateFormattedCompany = this.formatDate(data.expired_at);
    },
  },
};
</script>

<style>
.uppercase-input input,
.v-input {
  text-transform: uppercase;
}
</style>
<style lang="scss" scoped>
@import "@/assets/css/custom.scss";
// .box-page-new {
//   margin: 15px 0px;
// }
.v-text-field--filled:not(.v-text-field--single-line) input {
  margin-top: 0px !important;
}
.button-register {
  background-color: #00a4b3 !important;
  border-radius: 5px;
  color: white !important;
  text-align: center;
  text-transform: capitalize;
  height: 30px !important;
  min-width: 130px !important;
}
input,
select {
  color: $gray_2;
  option {
    color: $gray_2;
  }
  &.input-login {
    border: 1px solid #93969b;
    width: 100%;
    padding: 8px 16px;
    border-radius: 14px;
    background: white;
    font-size: 20px;
  }
  &.input-login-read {
    border: 1px solid #93969b;
    width: 100%;
    padding: 8px 16px;
    border-radius: 14px;
    background: #e9e9e9;
    font-size: 20px;
  }
  &:focus {
    outline: 1px solid $blue_2;
    outline: none;
  }
}
.hide-label {
  visibility: hidden;
}
.main-layout {
  background: #fff !important;
}
.title-page {
  color: $blue_1;
  margin-left: 15px;
  font-size: 24px;
  font-weight: 700;
}
a {
  text-decoration: none;
}
.containt {
  padding-bottom: 110px !important;
}
.image-profie {
  display: block;
  width: 150px;
  height: 150px;
  border-radius: 35px;
  object-fit: cover;
}
.item-info {
  color: #7b8cae;
}
.card--cart-order {
  padding: 15px 15px 0px 15px;
  border-radius: 16px;
  background: #fff;
  //   margin-top: 10px;
}
.card--cart {
  padding: 32px 15px;
}
.card--image {
  padding: 15px 15px;
  //   margin-top: 45px;
}
.blue-font {
  color: $blue_1;
  font-weight: 700;
  font-size: 16px;
}
.tabs {
  background: #e5e5e5 !important;
}
.content-table {
  padding: 35px 18px 18px 18px;
  @media screen and (min-width: 960px) {
    padding: 10px;
  }
  @media screen and (max-width: 958px) {
    padding: 5px 18px 18px 18px;
  }
}
.fontSet {
  font-size: 14px !important;
}
.box-page-new {
  margin: 20px;
  border-radius: 4px !important;
}
.field-padding {
  padding: 0 10px !important;
}
.field-padding-top {
  padding: 12px 10px 0px 10px !important;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
